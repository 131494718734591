body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  /*animation: App-logo-spin infinite 20s linear;*/
  -webkit-animation: bounce infinite 20s linear;
          animation: bounce infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);}
  40% {-webkit-transform: translateY(-30px) }
  60% {-webkit-transform: translateY(-20px);}
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);transform: translateY(0);}
  40% {-webkit-transform: translateY(-30px);transform: translateY(-30px);}
  60% {-webkit-transform: translateY(-20px);transform: translateY(-20px);}
}


.bounce {
  -webkit-animation-duration: .005s;
  animation-duration: .005s;
  -webkit-animation-name: bounce;
  animation-name: bounce;
}

.contact {
  color: white
}

a {
  color: white !important;
  cursor: pointer;
  text-decoration: none;
}
